import React from 'react'
import Helmet from 'react-helmet'
import { format } from 'date-fns'

import '../../assets/style/main.css'

export default ({ children, className = '', isHome = false }) => (
  <div className={`flex flex-col min-h-screen${isHome ? ' max-h-full' : ''}`}>
    <Helmet defaultTitle="Cagemannerisms by The Cageman" />
    <div className={`flex flex-col flex-1 px-8 w-screen ${className}`.trim()}>
      {children}
    </div>
    <footer className="flex-none h-16 mt-auto py-4 text-center text-xs w-full">
      &copy; Copyright {format(new Date(), 'yyyy')}. All rights reserved.
    </footer>
  </div>
)
