import React from 'react'
import { Link } from 'gatsby'

export const Menu = ({ data: menu }) => {
  const menuBlock = (
    <ul className="flex h-full">
      {menu.map(item => {
        return (
          <li className="inline-flex items-center" key={item.path}>
            <Link
              activeClassName="text-green"
              className="lowercase text-sm ml-4 no-underline hover:text-green"
              to={item.path}
            >
              {item.label}
            </Link>
          </li>
        )
      })}
    </ul>
  )

  return (
    <nav className="flex flex-none my-8 items-center justify-between container mx-auto">
      <Link className="lowercase text-2xl font-medium no-underline" to="/">
        The Cageman
      </Link>
      {menuBlock}
    </nav>
  )
}

export default Menu
